import { Collapse } from 'antd';

import styles from './index.module.scss';
import './index.scss';

const { Panel } = Collapse;

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

export const Faq = () => {
  const onChange = (key: string | string[]) => {
    console.log(key);
  };
  return (
    <section className={styles.container}>
      <div className={styles.wrapper}>
        <h3>Frequently Asked Questions </h3>
        <div className={styles.accordBox}>
          <Collapse
            expandIconPosition="end"
            bordered={false}
            defaultActiveKey={['1']}
            onChange={onChange}
            style={{ background: '#fff' }}
            // ghost={true}
          >
            <Panel
              style={{ border: 'none', padding: '1rem' }}
              header="What is included in a Zondos free trial?"
              key="1"
              className="customeHeader"
            >
              <p>
                Simply send us an email at support@zondos.com We’re checking it 24/7 and we’ll be
                happy to answer any of your questions.
              </p>
            </Panel>
            <Panel
              style={{ border: 'none', padding: '1rem' }}
              header="I have more questions. How can I reach you?"
              key="2"
              className="customeHeader"
            >
              <p>
                Simply send us an email at support@zondos.com We’re checking it 24/7 and we’ll be
                happy to answer any of your questions.
              </p>
            </Panel>
            <Panel
              style={{ border: 'none', padding: '1rem' }}
              header="How do you choose the best pricing plan?"
              key="3"
              className="customeHeader"
            >
              <p>{text}</p>
            </Panel>

            <Panel
              style={{ border: 'none', padding: '1rem' }}
              header="I’d like to get dedicated support to set everything up. Can I get a Customer Success Manager?"
              key="3"
              className="customeHeader"
            >
              <p>{text}</p>
            </Panel>

            <Panel
              style={{ border: 'none', padding: '1rem' }}
              header="This will be my first time setting up an automation workflow. Can I expect any help?"
              key="3"
              className="customeHeader"
            >
              <p>{text}</p>
            </Panel>
            <Panel
              style={{ border: 'none', padding: '1rem' }}
              header="What is the difference between Enterprise subscription plan andpay as you go?"
              key="3"
              className="customeHeader"
            >
              <p>{text}</p>
            </Panel>
          </Collapse>
        </div>
      </div>
    </section>
  );
};
