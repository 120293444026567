import { Link } from 'react-router-dom';

import { MainLayout, Footer } from '@/components/Layout/public';
import NavHeader from '@/components/Layout/public/NavHeader';

import { ContactForm } from './components/ContactForm';
import styles from './index.module.scss';

export const Contact = () => {
  return (
    <MainLayout>
      <main style={{ position: 'relative' }} className={styles.container}>
        <NavHeader />

        <section className={styles.containerBox}>
          <div className={styles.wrapper}>
            <div className={styles.left}>
              <div className={styles.leftHeader}>Contact Zondos</div>
              <div className={styles.leftDesc}>
                <span>
                  Have a question? Please let us know how we can help. If you are looking for
                  information about our products, please contact our <Link to="#">sales team.</Link>
                </span>
              </div>
            </div>
            <div className={styles.right}>
              <div className={styles.formContainer}>
                <ContactForm />
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </main>
    </MainLayout>
  );
};
