import * as yup from 'yup';

import { InputField, Textarea } from '@/components/Form';
import { Form } from '@/components/Form/Form';

import styles from './index.module.scss';

type contactType = {
  firstName: string;
  lastName: string;
  workEmail: string;
  jobTitle: string;
  companyName: string;
  content: string;
};

export const contactSchema = yup.object().shape({
  firstName: yup.string().trim().required('First name is required'),
  lastName: yup.string().trim().required('Last name is required'),
  workEmail: yup.string().trim().required('Email is required'),
  content: yup.string().trim().required('Content is required'),
});

export const ContactForm = () => {
  const onSubmit = (prop: any) => {
    console.log('anyy', prop);
  };
  return (
    <Form<contactType> submitHandler={onSubmit} scheme={contactSchema}>
      {({ register, formState }) => (
        <>
          <div className={styles.formInline}>
            <InputField
              label="First Name"
              error={formState.errors['firstName']}
              type="text"
              placeholder="First Name"
              registration={register('firstName')}
            />
            <InputField
              label="Last Name"
              placeholder="Last Name"
              error={formState.errors['lastName']}
              type="text"
              registration={register('lastName')}
            />
          </div>
          <div className={styles.formInline}>
            <InputField
              label="Job Title"
              error={formState.errors['jobTitle']}
              type="text"
              placeholder="Job Title"
              registration={register('jobTitle')}
            />
            <InputField
              label="Company Name"
              placeholder="Company Name"
              error={formState.errors['companyName']}
              type="text"
              registration={register('companyName')}
            />
          </div>

          <div className={styles.formfull}>
            <InputField
              label="Work Email"
              error={formState.errors['workEmail']}
              type="email"
              placeholder="Work Email"
              registration={register('workEmail')}
            />
          </div>
          <div className={styles.formfull}>
            <Textarea
              label="What can we help with?"
              error={formState.errors['content']}
              placeholder=""
              registration={register('content')}
            />
          </div>
          <div className={styles.formfull}>
            <button className={styles.submitBtn} type="submit">
              Send Message
            </button>
          </div>
        </>
      )}
    </Form>
  );
};
