// import { ReactComponent as Sicon1 } from '@/assets/icons/s1.svg';
// import { ReactComponent as Sicon2 } from '@/assets/icons/s2.svg';
// import { ReactComponent as Sicon3 } from '@/assets/icons/s3.svg';
// import { ReactComponent as Sicon4 } from '@/assets/icons/s4.svg';
// import { ReactComponent as Sicon5 } from '@/assets/icons/s5.svg';
// import { ReactComponent as Sicon6 } from '@/assets/icons/s6.svg';
import { MainLayout, Footer } from '@/components/Layout/public';
import NavHeader from '@/components/Layout/public/NavHeader';

// import { Services } from './components/Services';
import styles from './index.module.scss';

export const Terms = () => {
  return (
    <MainLayout>
      <main style={{ position: 'relative' }} className={styles.container}>
        <NavHeader />

        <section className={styles.containerBox}>
          <div className={styles.wrapper}>
            <div className={styles.left}>
              <h3>Terms of Service</h3>
              <p>
                By signing up to use Zondos, you agree to the terms and conditions described in
                these Terms of Service and our Anti-spam Policy. This is a legal agreement, so
                please read all of this documentation carefully. The following terms and conditions
                govern all use of the zondos.com website and all content, services and products
                available at or through the website, including, but not limited to, sending email
                through Zondos (hereafter referred to as the “Services” or the “Service” or the
                “Agreement”). If you have any questions about these Terms of Service or our
                Anti-spam policy, please contact us at{' '}
                <a href="mailto:suport@zondos.com">suport@zondos.com</a>
              </p>
            </div>
            <div className={styles.left}>
              <h4>Eligibility</h4>

              <div>
                <ul className={styles.eliOrder}>
                  <li>You must be at least 18 years of age to use Zondos.</li>
                  <li>
                    You must provide true and complete contact information in the profile of your
                    account.
                  </li>
                  <li>You must agree to our terms, conditions and rules and abide by them.</li>
                </ul>
                <p>
                  By signing up, you warrant that you meet the above eligibility requirements.
                  Zondos reserves the right to close or shut down accounts of any users for any
                  reason disclosed or undisclosed and to change these eligibility requirements at
                  any time.
                </p>
              </div>
            </div>

            <div className={styles.left}>
              <h4>General Rules</h4>

              <div>
                <ul className={styles.eliOrder}>
                  <li>
                    You are only allowed to have one Zondos account sending the same or similar
                    content. If your account is suspended or disabled, creating another account will
                    automatically result in cancellation
                  </li>
                  <li>
                    You must not delete, bulk unsubscribe, or otherwise modify your list in order to
                    avoid our billing thresholds.
                  </li>
                  <li>
                    You must not use false or misleading header information. Your “From,” “To,”
                  </li>
                  <li>
                    You must not use false or misleading header information. Your “From,” “To,”
                    “Reply-To,” and routing information – including the originating domain name and
                    email address – must be accurate and true and must identify the person or
                    business who initiated the message. We do not allow sending from generic address
                    such as gmail.com, yahoo.com, hotmail.com, outlook.com etc.
                  </li>
                  <li>
                    You must not use deceptive subject lines or redirect links to sites and pages
                    that are not relevant to your mail.
                  </li>
                  <li>
                    You agree not to use any content, including graphics that is not either created
                    by you or provided for you to use or would infringe or violate on any other
                    party’s rights.
                  </li>
                  <li>
                    If you send advertisements, you must identify the message as an ad. You must
                    disclose clearly and conspicuously that your message is an advertisement.
                  </li>
                  <li>
                    Your message must include your valid physical postal address. This can be your
                    current street address, a post office box you have registered with your Postal
                    Service, or a private mailbox you have registered with a commercial mail
                    receiving agency established under Postal Service regulations.
                  </li>
                  <li>
                    Your message must include a clear and conspicuous explanation of how the
                    recipient can opt out of getting email from you in the future.
                  </li>
                  <li>Honor opt-out requests promptly.</li>
                </ul>
              </div>
            </div>

            <div className={styles.left}>
              <h4>Account and Passwords</h4>
              <p>
                If you create an online account on zondos.com, you are responsible for maintaining
                the security of your account and the API key assigned to you. It is up to you as the
                account owner to keep your password and account name confidential. You are fully
                responsible for all activities that occur under the account and API Key and any
                other actions taken in connection with your account or the use of the API by that
                account or API Key. You will contact Sender immediately if you suspect a breach of
                security regarding your account.
              </p>
            </div>

            <div className={styles.left}>
              <h4>Payment</h4>
              <p>
                <strong>Prepaid email credits:</strong> You may purchase prepaid email credits (one
                email credit equals one email sent) and these credits will remain in your account
                for you to use as long as your account is considered active. If your account shows
                no activity for a period of 12 months, your account may be considered inactive and
                your email credit may expire.
                <br /> <strong>Monthly subscriptions:</strong> Payments are due for any month on the
                same or closest date to the day you made your first monthly payment (the “Pay
                Date”). If you go over your sending limit and reach another pricing level, then
                you’ll have to pay at the higher level on or before the next pay date. If the Term
                ends before that payment is due, you’ll still be required to make one payment at the
                higher level.
              </p>
            </div>
            <div className={styles.left}>
              <h4>Change</h4>
              <p>
                Zondos reserves the right, at its sole discretion, to modify or replace any part of
                its policies, terms, conditions or rules. It is your responsibility to review these
                terms periodically for changes. Any changes made to Zondos’s Terms of Service or
                Anti-Spam Policy go into effect immediately and your continued use of or access to
                the Service following the posting of any changes to this Agreement constitutes
                acceptance of those changes.
              </p>
            </div>
            <div className={styles.left}>
              <h4>Disputes</h4>
              <p>
                You agree to submit any disputes regarding charges or Zondos credits in writing
                within 30 days of the charge or purchase. You agree that any disputes rendered
                outside that time period are void and any charges are considered irrefutable.
              </p>
            </div>
            <div className={styles.left}>
              <h4>Refunds</h4>
              <p>
                Any requests for refunds must be in writing by contacting{' '}
                <a href="mailto:suport@zondos.com">suport@zondos.com</a> All requests for refunds
                must be within 30 days of the purchase date or they are considered irrefutable. If
                you break any of the rules, conditions, requirements or laws laid out in the Terms
                of Service or Anti-spam Policy you will not qualify for a refund under any
                circumstances.
              </p>
            </div>
            <div className={styles.left}>
              <h4>Termination</h4>
              <p>
                Zondos has the right to, at its sole discretion refuse or send any emails that, in
                Zondos’s opinion, violate any Zondos policy or are in any way harmful or
                objectionable, or terminate or deny access to and use of the Services to any
                individual or entity for any reason, in Zondos’s sole discretion. You also have the
                option of canceling your account at any time without penalty.
              </p>
            </div>
            <div className={styles.left}>
              <h4>Data retention period</h4>
              <p>
                Personal data collected by Zondos relating to the identity and contact details of
                its users and prospects is stored for a period of five years following the
                termination of the contractual relationship for data relating to users, or from the
                time it was collected by the data controller or from the last contact made by the
                prospect for data relating to prospects. The termination of the contractual
                relationship is understood as being the deliberate cancellation of the contract by
                the user or the non-use of Zondos’s services for a period of five years.
              </p>
            </div>

            <div className={styles.left}>
              <h4>Limitation of Liability</h4>
              <p>
                <strong>
                  To the maximum extent permitted by law, you assume full responsibility for any
                  loss that results from your use of the zondos.com website and the zondos.com
                  service.
                </strong>{' '}
                In no event shall Zondos be liable for any indirect, special, incidental or
                consequential damages (including but not limited to loss of use, loss of profits, or
                loss of data) whether in an action in contract, equity or otherwise, arising out of
                or in any way connected with the use of or inability to use this site or the
                materials therein or resulting from unauthorized access to or alteration of data.
              </p>
            </div>
            <div className={styles.left}>
              <h4>Indemnity</h4>
              <p>
                You agree to indemnify and hold us and our Team harmless from any losses, including
                attorney fees that result from any claims you make that aren’t allowed under these
                Terms due to a “Limitation of Liability” or other provision. You also agree to
                indemnify and hold us harmless from any losses, including attorney fees that result
                from third-party claims that you or someone using your password did something that,
                if true, would violate any of these Terms.
              </p>
            </div>

            <div className={styles.left}>
              <h4>Extent</h4>
              <p>
                This Agreement, including the policies included in our Anti-Spam Policy constitutes
                the entire agreement between zondos and you, and which you, the user have agreed to
                concerning the subject matter herein and pertaining to services as outlined on the
                website sender.net.
              </p>
            </div>
            <div className={styles.left}>
              <h4>Choice of Law</h4>
              <p>
                The laws of Nigeria will apply to any disputes arising out of or relating to these
                terms or the Services. All claims arising out of or relating to these terms or the
                Services will be litigated exclusively in courts of Nigeria and you and Zondos
                consent to personal jurisdiction in those courts.
              </p>
            </div>
          </div>
        </section>

        <Footer />
      </main>
    </MainLayout>
  );
};
