import { FiMenu } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import Logo from '@/components/Logo';
import { isLoggedIn } from '@/utils/isLoggedIn';

import styles from './index.module.scss';

export const NavHeader = () => {
  return (
    <nav className={styles.container}>
      <div className={styles.headerLeft}>
        <Logo />
      </div>
      <ul className={styles.headerRight}>
        <li>
          <Link to="#">API Documentation</Link>
        </li>
        <li>
          <Link to="/pricing">Price</Link>
        </li>

        <li>
          <Link to="/faq">FAQs</Link>
        </li>
        <li>
          <Link to="/about">About</Link>
        </li>
        <li>
          <Link to="/contact">Contact Us</Link>
        </li>
      </ul>
      <ul className={styles.authCont}>
        {!isLoggedIn() ? (
          <li className={styles.signin}>
            <Link to="/auth/signup">Create an Account</Link>
          </li>
        ) : (
          <li className={styles.signin}>
            <Link to="/app/dashboard">My Account</Link>
          </li>
        )}
        {!isLoggedIn() && (
          <li className={styles.demolink}>
            <Link to="/auth/login">
              <span className={styles.bookTitle}>Login</span>
            </Link>
          </li>
        )}

        <li>
          <FiMenu size="33" />
        </li>
      </ul>
    </nav>
  );
};

export default NavHeader;
