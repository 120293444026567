import { Navigate, useRoutes } from 'react-router-dom';

import { Landing, Pricing, About, Contact, Privacy, Terms, Faq } from '@/features/publicArea';
import { isLoggedIn } from '@/utils/isLoggedIn';

import { protectedRoutes } from './protected';
import { publicRoutes } from './public';

export const AppRoutes = () => {
  const loggedIn = isLoggedIn();

  const commonRoutes = [
    { path: '/', element: <Landing /> },
    { path: '/pricing', element: <Pricing /> },
    { path: '/about', element: <About /> },
    { path: '/contact', element: <Contact /> },
    { path: '/privacy', element: <Privacy /> },
    { path: '/terms', element: <Terms /> },
    { path: '/faq', element: <Faq /> },
    { path: '*', element: <Navigate to="/" /> },
  ];
  const routes = loggedIn ? protectedRoutes : publicRoutes;
  const element = useRoutes([...routes, ...commonRoutes]);
  return <>{element}</>;
};
