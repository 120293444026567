import { AiOutlineGooglePlus } from 'react-icons/ai';
import { BsTwitter } from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';
import { IoMailUnreadSharp } from 'react-icons/io5';
import { Link } from 'react-router-dom';

import Logo from '@/assets/images/logo-dark-removebg1.png';

import styles from './index.module.scss';

export const Footer = () => {
  return (
    <footer className={styles.container}>
      <section className={styles.wrapper}>
        <div className={styles.left}>
          <div className={styles.imgCont}>
            <img src={Logo} alt="logo light" className={styles.imgWrapper} />
          </div>

          <div className={styles.companySummary}>
            Zondos is Nigeria&apos;s smartest, fastest, and most reliable way of sending text and
            email messages, reaching thousands of people with a single click.
          </div>

          <button className={styles.supportBtn}>
            <span>
              <IoMailUnreadSharp color="#fff" />
            </span>
            <span>support@zondos.com</span>
          </button>
        </div>
        <div className={styles.right}>
          <div className={styles.aboutUs}>
            <h3>Who We Are</h3>
            <ul className={styles.aboutList}>
              <li>Pricing</li>
              <li>About Us</li>
              <li>Contact Us</li>
            </ul>
          </div>

          <div className={styles.aboutUs}>
            <h3>Useful Links</h3>
            <ul className={styles.aboutList}>
              <li>Login</li>
              <li>Create an Account</li>
              <li>Try for Free</li>
            </ul>
          </div>
        </div>
      </section>

      <section className={styles.footerBottom}>
        <div className={styles.footerBLeft}>
          <Link to="/privacy">Privacy Policy </Link>
          <Link to="/terms">Terms </Link>
        </div>
        <div className={styles.footerBRight}>
          <Link to="#">
            <BsTwitter size={25} />
          </Link>
          <Link to="#">
            <FaFacebookF size={25} />
          </Link>
          <Link to="#">
            <AiOutlineGooglePlus size={25} />
          </Link>
        </div>
      </section>
    </footer>
  );
};
