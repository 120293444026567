import { MainLayout, Footer } from '@/components/Layout/public';
import NavHeader from '@/components/Layout/public/NavHeader';

import styles from './index.module.scss';

export const Privacy = () => {
  return (
    <MainLayout>
      <main style={{ position: 'relative' }} className={styles.container}>
        <NavHeader />

        <section className={styles.containerBox}>
          <div className={styles.wrapper}>
            <div className={styles.left}>
              <h3>Privacy Policy</h3>
              <p>
                This privacy policy applies to www.zondos.com. We realize that you care how your
                information is processed and shared, and we appreciate your trust in us to do that
                carefully and sensibly. This document describes our Privacy Policy. By visiting our
                website at zondos.com or using our service, you are accepting and consenting to the
                practices described in this Privacy Policy.
              </p>
            </div>

            <div className={styles.left}>
              <h4>General</h4>
              <p>
                Zondos is a company that provides digital marketing and ancillary services for its
                clients. Zondos collects personal information about you during your use of our
                website and services and through our cookie technology as you use and navigate our
                website and service. Zondos is the Data Processor, our customer is the Data
                Controller. If you provide us with personal information (your name, postal address,
                telephone, or email address), or have done so in the past, via our websites, our
                publications or surveys, we may use that information to send you updates on our
                latest services and promotions or to contact you so that we may learn more about our
                customers. You can choose to “opt out” of these communications. To state your
                contact preferences please contact us at{' '}
                <a href="mailto:support@zondos.com.">support@zondos.com.</a> To opt out of any email
                communications from us, please click the ‘unsubscribe’ link at the footer of the
                email in question. We may use your data to collate and create an information
                database of visitors to our website in order to improve our website and our service
                and to provide relevant services and information to you. We may also share any
                aggregated information, but not individual data, with third parties which offer
                their goods or services on our websites to help them better understand our services
                and monitor their advertising reach on the sites.
              </p>
            </div>

            <div className={styles.left}>
              <h4>Customer Data Processing</h4>
              <p>
                Zondos collects personal information from Customers who visit the Website and
                register with our Service. Zondos is the data controller in regard to the Customer’s
                personal data. When the Customer registers with our Service, we require: username,
                password, email address, first and last name, country and billing information such
                as address. We may also ask Customers to provide additional personal information
                such as company name and other company and personal details that the Customer may
                opt out of by not entering data when asked. We use the Customers’ registration
                information to authenticate users and provide access to the Service. We also use the
                email addresses used in the registration information to communicate with our
                Customers. From time to time, Zondos may contact you via email to notify you of
                changes to its Service, scheduled maintenance, information about Zondos and
                promotional material from Zondos. The Customer is obliged to update the data
                provided in the registration form as soon as any change in these data has occurred.
                We will retain your information for as long as needed to provide you the Service. We
                will retain and use your information as necessary to comply with our legal
                obligations, resolve disputes, and enforce our agreements. Provision of personal
                data in connection with your use of the Service is voluntary. Note, however, that
                the refusal to provide certain data may make it impossible for you to use all or
                part of the Service functionalities.
              </p>
            </div>

            <div className={styles.left}>
              <h4>Sharing Information Regarding the Customers and Subscribers</h4>
              <p>
                Zondos will not share, sell, rent or trade the Customer’s and Subscriber’s personal
                information other than as disclosed within this Privacy Policy. We reserve the right
                to disclose the Customer’s and Subscriber’s personal information as required by law
                and when we believe that disclosure is necessary to protect our rights and/or comply
                with a judicial proceeding, Court order, or legal process served on Sender. We use
                other third parties in a scope necessary to provide the Service, such as a credit
                card or debit card processing company to bill the Customer for services. When you
                sign up for the Service we will share your information as necessary for the third
                party to provide the Service. These third parties are prohibited from using your
                personal information for promotional purposes. If Zondos is involved in a merger,
                acquisition, or sale of all or a portion of its assets, you will be notified via
                email and/or a prominent notice on the Website of any change in ownership or uses of
                your personal information, as well as any choices you may have regarding your
                personal information.
              </p>
            </div>

            <div className={styles.left}>
              <h4>Website Use Information</h4>
              <p>
                Our servers automatically collect site use information, each time you visit the
                Website. The Website use information includes, but is not limited to the following:
                domain names, operating system in use (e.g. macOS, Windows), browser (e.g. Chrome,
                Mozilla Firefox, Internet Explorer) and version, the Website which referred you to
                us, and other similar information. This information may be aggregated to measure the
                number of visits, average time spent on the Website, pages viewed, time and date of
                visits, and other similar information. We may use and disclose site use information,
                for example, to measure the use of the Website, improve the content, explain the
                utility of the Website and services we provide, and to extend their functionality.
                As is true of most Websites, we gather certain information automatically and store
                it in log files. This information includes browser type, referring/exit pages,
                operating system, date/time stamp, and click data. We use this information, which
                does not identify individual users, to analyze trends, to administer the Website, to
                track users’ movements around the Website and to gather demographic information
                about our user base as a whole. We do not link this automatically-collected data to
                personal information. Similar to other commercial websites, “cookies” may be used to
                provide you with tailored information. A cookie is a small piece of data that a
                website can send to your browser, which may then be stored on your hard drive, so we
                can recognize you when you return. You may be able to set your browser to notify you
                when you receive a cookie. Our cookies collect general information that enhances our
                ability to serve you and measure the utility of the Website. We do not link the
                information we store in cookies to any personal information you submit while on the
                Website. The use of cookies by third party web analytics partner is not covered by
                our Privacy Policy. We do not have access or control over these cookies. These third
                parties use session ID and persistent cookies to make it easier for you to navigate
                the Website. Information we collect may be used to enhance your use of the Website,
                and to provide you with the Service, arrange the Website in the most customer
                friendly way, communicate special offers and featured items, and/or respond to your
                questions and suggestions. In the case of an agreement, personal information
                includes data that identifies you as the specific individual, i.e. your name, email
                address, phone number, geographical address, and/or company name and company
                address. This information is used to complete the account application process,
                Customer’s information request, and/or to verify the validity of the request, and to
                process payment. We will use this information solely for processing payments for the
                Service that you ordered. Zondos collects only personal information that you choose
                to provide voluntarily registering to our Service. Zondos shall advise you which
                information is mandatory to provide and what you do not have to fill in.
              </p>
            </div>

            <div className={styles.left}>
              <h4>Information Security</h4>
              <p>
                We believe that keeping personal information secure is one of our most significant
                responsibilities. We restrict access to personal information about you to those
                employees and others who need to know that information to assist us in our business,
                or to provide services to you. We safeguard personal information, according to the
                established security standards and procedures, by maintaining physical, electronic,
                and procedural safeguards to the personal data information. When we request
                Customers to enter sensitive information, such as credit card number, it is
                encrypted and protected with the best encryption software in the industry SSL and
                processed through a reputable payment processor. While on a secure page, such as our
                order form, the lock icon at the bottom of web browsers, e.g. Mozilla Firefox or
                Microsoft Internet Explorer, becomes locked, as opposed to unlocked or open, when
                you are just ‘surfing’. All of our Customers’ information, not just the sensitive
                information mentioned above, is restricted in our offices. Only employees who need
                the information to perform a specific job (e.g. our billing clerk or a customer
                service representative) are personally granted access to sensitive information.
              </p>
            </div>

            <div className={styles.left}>
              <h4>Security</h4>
              <p>
                Zondos stores its servers in a highly secure server environment, with surveillance
                and support to prevent unauthorized access and data security. We cannot guarantee
                the security of your data while it is being transmitted over the Internet and
                through servers that are out of our control. We strive to protect your personal
                information but Zondos cannot ensure or warrant the security of any information you
                transmit to the Website or Services. Any data transmissions you make over the
                Internet are done so at your own risk. Once we receive the data transmission, we
                make our best efforts to ensure its security and privacy on our systems.
              </p>
            </div>

            <div className={styles.left}>
              <h4>Customer Testimonials/Reviews</h4>
              <p>
                We post customer testimonials/reviews on the Website which may contain personal
                information. We do obtain the customer’s consent via email prior to posting the
                testimonial/review to post their name along with their testimonial/review. If you
                wish to update or delete your testimonial, please contact us at{' '}
                <a href="mailto:support@zondos.com ">support@zondos.com </a>
              </p>
            </div>

            <div className={styles.left}>
              <h4>View, Change or Remove Information</h4>
              <p>
                You may change or delete your profile information at any time by going to your
                profile page. Please contact us if you need assistance in updating or reviewing your
                information at
                <a href="mailto:support@zondos.com ">support@zondos.com </a>
              </p>
            </div>

            <div className={styles.left}>
              <h4>Other Terms</h4>
              <p>
                We may change this Privacy Policy at any time. Unless stated otherwise, our current
                Privacy Policy applies to all information that we have about you and your account.
                If we make changes to this Privacy Policy we will notify you by publication here
                prior to the changes taking effect. If, however, we make material changes or Zondos
                is going to use users’ personal information in a manner different from that stated
                at the time of collection we will notify users via the Website prior to the change
                becoming effective. Users will have a choice as to whether or not we use their
                information in this different manner. However, if users have opted out of all
                communication with the Website, or deleted their account, then they will not be
                contacted, nor will their personal information be used in this new manner.
              </p>
            </div>
          </div>
        </section>

        <Footer />
      </main>
    </MainLayout>
  );
};
