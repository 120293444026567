import { Link } from 'react-router-dom';

import Logos from '../../assets/images/logo-light-removebg.png';

import styles from './index.module.scss';

const Logo = () => {
  return (
    <Link to="/">
      <img className={styles.logo} src={Logos} alt="logo" />
    </Link>
  );
};

export default Logo;
