import { lazyImport } from '@/utils/lazyimport';

const { Login } = lazyImport(() => import('@/features/auth'), 'Login');
const { Signup } = lazyImport(() => import('@/features/auth'), 'Signup');
const { VerifyEmail } = lazyImport(() => import('@/features/auth'), 'VerifyEmail');
const { Otp } = lazyImport(() => import('@/features/auth'), 'Otp');
const { VerifyPassword } = lazyImport(() => import('@/features/auth'), 'VerifyPassword');
const { ResetPassword } = lazyImport(() => import('@/features/auth'), 'ResetPassword');

export const publicRoutes = [
  {
    path: '/auth/login',
    element: <Login />,
  },

  {
    path: '/auth/signup',
    element: <Signup />,
  },
  {
    path: '/auth/verify-email',
    element: <VerifyEmail />,
  },
  {
    path: '/auth/otp',
    element: <Otp />,
  },
  {
    path: '/auth/verify-password',
    element: <VerifyPassword />,
  },
  {
    path: '/auth/reset-password',
    element: <ResetPassword />,
  },
];
