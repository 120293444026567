import { useEffect } from 'react';

import Rec1 from '@/assets/images/A.webp';
import Rec2 from '@/assets/images/B.webp';
import Rec3 from '@/assets/images/C.webp';
import Rec4 from '@/assets/images/D.webp';
import { MainLayout, Footer } from '@/components/Layout/public';
import * as nprogress from '@/libs/nploader';

import { Faq } from './components/Faq';
import { Features } from './components/Feature';
import { Header } from './components/Header';
import { Sale } from './components/Sale';
import styles from './index.module.scss';

export const Landing = () => {
  useEffect(() => {
    nprogress.stopLoading();

    return () => {
      nprogress.startLoading();
    };
  }, []);
  return (
    <MainLayout>
      <main style={{ position: 'relative' }} className={styles.container}>
        <Header />
        <Sale />
        <Features
          btnText="Start Customising"
          src={Rec1}
          description="Zondos is the most advanced Bulk SMS & drag & drop editor on the market. Create e-mails, newsletters, bulk SMS and landing pages with  high graphic impact. Optimized for mobile, these are dynamically composed and, above all, performing."
          title="Create emails and  SMS that are irresistible to your customers"
        />
        <Features
          btnText="Experience Zondos"
          src={Rec2}
          orderLeft={1}
          orderRight={0}
          background="#fff"
          description="Instead of paying a monthly recurring charge, you can buy email credit as you want on zondos. With our spend less do more bulk sms,  you can engage customers across the entire promotional"
          title="Pay As You Go"
        />
        <Features
          btnText="Start Campaigning"
          src={Rec3}
          description="Get a complete picture of your contacts, workflows, collaborate seamlessly and win more customers. All from a single, easy-to-manage dashboard"
          title="House all sales and marketing activities in one place"
        />
        <Features
          orderLeft={1}
          orderRight={0}
          src={Rec4}
          background="#fff"
          btnText="Try for Free"
          description="Compare campaigns, newsletters: bulk sms, Our easy-to-use solutions alight everything you need you need."
          title="Analytics"
        />

        <div className={styles.faqCont}>
          <Faq />
        </div>

        <Footer />
      </main>
    </MainLayout>
  );
};
