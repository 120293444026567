import { Approvider } from '@/providers/app';
import './App.css';
import { AppRoutes } from '@/routes/index';

function App() {
  return (
    <Approvider>
      <AppRoutes />
    </Approvider>
  );
}

export default App;
