import styles from './index.module.scss';

type ServicesProps = {
  Icon: React.ReactElement;
  title: string;
  description: string;
};

export const Services = ({ Icon, title, description }: ServicesProps) => {
  return (
    <div className={styles.serviceItem}>
      <span className={styles.iconBox}>{Icon}</span>
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{description}</div>
    </div>
  );
};
