import styles from './index.module.scss';

export const Sale = () => {
  return (
    <section className={styles.saleContainer}>
      <h3 className={styles.saleContainerTitle}>
        Accelerate your sales and grow your business on a single platform
      </h3>
      <p className={styles.saleContainerPara}>
        Get all the marketing and sales tools you need for your multichannel marketing and sales
        strategies in order to increase profits, streamline your workflows, and delight your
        customers
      </p>
    </section>
  );
};
