import {
  DefaultOptions,
  QueryClient,
  UseMutationOptions,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';

const queryConfig: DefaultOptions = {
  queries: {
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: 100000,
    // useErrorBoundary: true,
  },
};
export const queryClient = new QueryClient({ defaultOptions: queryConfig });

export type ExtractFnReturnType<FnType extends (...args: any) => any> = Awaited<ReturnType<FnType>>;

export type mutationConfig<FnType extends (...args: any) => any> = UseMutationOptions<
  ExtractFnReturnType<FnType>,
  AxiosError,
  Parameters<FnType>[0]
>;

export type QueryConfig<FnType extends (...args: any) => any> = Omit<
  UseQueryOptions<ExtractFnReturnType<FnType>, Error>,
  'queryKey' | 'queryFn'
>;
