// import { Table } from '@/components/Element';
import { useEffect } from 'react';
import { BsArrowRight, BsFillCheckCircleFill } from 'react-icons/bs';

import { MainLayout, Footer } from '@/components/Layout/public';
import NavHeader from '@/components/Layout/public/NavHeader';
import * as nprogress from '@/libs/nploader';

import styles from './index.module.scss';

export const Pricing = () => {
  useEffect(() => {
    nprogress.stopLoading();

    return () => {
      nprogress.startLoading();
    };
  }, []);
  return (
    <MainLayout>
      <main style={{ position: 'relative' }} className={styles.container}>
        <NavHeader />

        <section className={styles.containerBox}>
          <div className={styles.header}>
            <h3>Our Pricing</h3>
            <p>
              Our pricing system is simple, the more you buy, the more units you get and there is no
              maximum limit to the number of units you can purchase at any time. We have two plans
              for you to choose from.
            </p>
          </div>

          {/* <section className={styles.sectionPrice}>
            <div className={styles.priceSection}>
              <aside className={styles.leftAside}>
                <h6 className={styles.asideTitle}>Start with your free account</h6>

                <p className={styles.asideContent}>
                  Sign up and start using zondos for free. Create your first SMS & Email campaigns:
                  enjoy up to free 500 outbound emails and manage up to 500 contacts.
                </p>

                <p className={styles.asideFooter}>No credit card required.</p>
              </aside>
              <aside className={styles.rightAside}>
                <div className={styles.rightAsideContainer}>
                  <div className={styles.buttonWrap}>
                    <h3>Free</h3>
                    <h4>₦0</h4>
                  </div>
                  <div className={styles.priceProps}>
                    <ul className={styles.priceList}>
                      <li className={styles.priceListItem}>
                        <BsFillCheckCircleFill color="#7c2ecb" />
                        <p>1 Sub-account</p>
                      </li>
                      <li className={styles.priceListItem}>
                        <BsFillCheckCircleFill color="#7c2ecb" />

                        <p>1 User</p>
                      </li>
                      <li className={styles.priceListItem}>
                        <BsFillCheckCircleFill color="#7c2ecb" />
                        <p>Up to 500 contacts (Email & SMS each)</p>
                      </li>
                      <li className={styles.priceListItem}>
                        <BsFillCheckCircleFill color="#7c2ecb" />

                        <p>Landing pages of up to 1,000 visits</p>
                      </li>

                      <li className={styles.priceListItem}>
                        <BsFillCheckCircleFill color="#7c2ecb" />

                        <p> Shipment preview and test shipments</p>
                      </li>
                      <li className={styles.priceListItem}>
                        <BsFillCheckCircleFill color="#7c2ecb" />

                        <p> Contact Reputation</p>
                      </li>
                      <li className={styles.priceListItem}>
                        <BsFillCheckCircleFill color="#7c2ecb" size={19} />

                        <p> Drag and Drop Editor (customizable email templates)</p>
                      </li>
                    </ul>
                  </div>

                  <div className={styles.buttonWrapFooter}>
                    <button className={styles.priceTypeBtnVariant}>
                      <span>Start a free trial</span>
                      <span>
                        <BsArrowRight />
                      </span>
                    </button>
                  </div>
                </div>
              </aside>
            </div>
          </section> */}

          <section className={styles.sectionPrice}>
            <div className={styles.priceSection}>
              {/* <aside className={styles.leftAside} style={{ order: '1' }}>
                <h6 className={styles.asideTitle}>Start with your free account</h6>

                <p className={styles.asideContent}>
                  Sign up and start using zondos for free. Create your first SMS & Email campaigns:
                  enjoy up to free 500 outbound emails and manage up to 500 contacts.
                </p>

                <p className={styles.asideFooter}>No credit card required.</p>
              </aside> */}
              <aside className={styles.rightAside} style={{ order: '0' }}>
                <div className={styles.rightAsideContainer}>
                  <div className={styles.buttonWrap}>
                    <h3>Free</h3>
                    <h4>₦0</h4>
                  </div>
                  <div className={styles.priceProps}>
                    <ul className={styles.priceList}>
                      <li className={styles.priceListItem}>
                        <BsFillCheckCircleFill color="#7c2ecb" />
                        <p>1 Sub-account</p>
                      </li>
                      <li className={styles.priceListItem}>
                        <BsFillCheckCircleFill color="#7c2ecb" />

                        <p>1 User</p>
                      </li>
                      <li className={styles.priceListItem}>
                        <BsFillCheckCircleFill color="#7c2ecb" />
                        <p>Up to 500 contacts (Email & SMS each)</p>
                      </li>
                      <li className={styles.priceListItem}>
                        <BsFillCheckCircleFill color="#7c2ecb" />

                        <p>Landing pages of up to 1,000 visits</p>
                      </li>

                      <li className={styles.priceListItem}>
                        <BsFillCheckCircleFill color="#7c2ecb" />

                        <p> Shipment preview and test shipments</p>
                      </li>
                      <li className={styles.priceListItem}>
                        <BsFillCheckCircleFill color="#7c2ecb" />

                        <p> Contact Reputation</p>
                      </li>
                      <li className={styles.priceListItem}>
                        <BsFillCheckCircleFill color="#7c2ecb" size={19} />

                        <p> Drag and Drop Editor (customizable email templates)</p>
                      </li>
                    </ul>
                  </div>

                  <div className={styles.buttonWrapFooter}>
                    <button className={styles.priceTypeBtnVariant}>
                      <span>Start a free trial</span>
                      <span>
                        <BsArrowRight />
                      </span>
                    </button>
                  </div>
                </div>
              </aside>
              <aside className={styles.rightAside} style={{ order: '0' }}>
                <div className={styles.rightAsideContainer}>
                  <div className={styles.buttonWrap}>
                    <h3>Prepaid SMS</h3>
                    <h4>
                      ₦3<sup>Per SMS</sup>
                    </h4>
                  </div>
                  <div className={styles.priceProps}>
                    <ul className={styles.priceList}>
                      <li className={styles.priceListItem}>
                        <BsFillCheckCircleFill color="#7c2ecb" />
                        <p>No Setup Fee</p>
                      </li>
                      <li className={styles.priceListItem}>
                        <BsFillCheckCircleFill color="#7c2ecb" />

                        <p>Unlimited Contacts</p>
                      </li>
                      <li className={styles.priceListItem}>
                        <BsFillCheckCircleFill color="#7c2ecb" />
                        <p>Dedicated Account Rep</p>
                      </li>
                      <li className={styles.priceListItem}>
                        <BsFillCheckCircleFill color="#7c2ecb" />

                        <p>CRM Integrations Available</p>
                      </li>

                      <li className={styles.priceListItem}>
                        <BsFillCheckCircleFill color="#7c2ecb" />

                        <p>Compliance & Deliverability Monitoring</p>
                      </li>
                    </ul>
                  </div>

                  <div className={styles.buttonWrapFooter}>
                    <button
                      className={`${styles.priceTypeBtnVariant} ${styles.priceTypeBtnInActive}`}
                    >
                      <span>Choose Plan</span>
                      <span>
                        <BsArrowRight />
                      </span>
                    </button>
                  </div>
                </div>
              </aside>
              <aside className={styles.rightAside} style={{ order: '0' }}>
                <div className={styles.rightAsideContainer}>
                  <div className={styles.buttonWrap}>
                    <h3>Prepaid Emails</h3>
                    <h4>
                      ₦3<sup>Per Email</sup>
                    </h4>
                  </div>
                  <div className={styles.priceProps}>
                    <ul className={styles.priceList}>
                      <li className={styles.priceListItem}>
                        <BsFillCheckCircleFill color="#7c2ecb" />
                        <p>Contact Reputation</p>
                      </li>
                      <li className={styles.priceListItem}>
                        <BsFillCheckCircleFill color="#7c2ecb" />

                        <p>Access to reports</p>
                      </li>
                      <li className={styles.priceListItem}>
                        <BsFillCheckCircleFill color="#7c2ecb" />
                        <p>Schedule </p>
                      </li>
                      <li className={styles.priceListItem}>
                        <BsFillCheckCircleFill color="#7c2ecb" />

                        <p>& More</p>
                      </li>
                    </ul>
                  </div>

                  <div className={styles.buttonWrapFooter}>
                    <button
                      className={`${styles.priceTypeBtnVariant} ${styles.priceTypeBtnInActive}`}
                    >
                      <span>Choose Plan</span>
                      <span>
                        <BsArrowRight />
                      </span>
                    </button>
                  </div>
                </div>
              </aside>
            </div>
          </section>
        </section>

        <Footer />
      </main>
    </MainLayout>
  );
};
