import { Suspense, lazy } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const Dashboard = lazy(() => import('@/features/loggedArea/dashboard/routes'));
const SMS = lazy(() => import('@/features/loggedArea/sms/route'));
const Contacts = lazy(() => import('@/features/loggedArea/contact/route'));
const Email = lazy(() => import('@/features/loggedArea/email/routes'));

const Template = lazy(() => import('@/features/loggedArea/template/routes'));
const Wallet = lazy(() => import('@/features/loggedArea/wallet/router'));

function App() {
  return (
    <div>
      <Suspense fallback={<div>Loading</div>}>
        <Outlet />
      </Suspense>

      <ToastContainer style={{ fontSize: '1.6rem' }} />
    </div>
  );
}

export const protectedRoutes = [
  {
    path: '/app',
    element: <App />,
    children: [
      {
        path: 'dashboard',
        element: <Dashboard />,
      },
      {
        path: 'sms',
        element: <SMS />,
      },
      {
        path: 'email',
        element: <Email />,
      },
      {
        path: 'contact',
        element: <Contacts />,
      },
      {
        path: 'template',
        element: <Template />,
      },
      {
        path: 'wallet',
        element: <Wallet />,
      },
      { path: '*', element: <Navigate to="/" /> },
    ],
  },
];
