import { ReactComponent as Announce } from '@/assets/icons/ann.svg';
import { ReactComponent as Calender } from '@/assets/icons/calender.svg';
import { ReactComponent as Mail } from '@/assets/icons/mail.svg';
import { ReactComponent as Search } from '@/assets/icons/search.svg';
import NavHeader from '@/components/Layout/public/NavHeader';

import { Phone } from '../phone';

import styles from './index.module.scss';

export const Header = () => {
  return (
    <section className={styles.header}>
      <section className={styles.navHeader}>
        <NavHeader />
        <section className={styles.hero}>
          <aside className={styles.leftAside}>
            <header>Engage your customers wherever they are</header>

            <p>
              We help you build strong relationships with your customers throughout the entire
              buying process with Zondos.
            </p>

            <button className={styles.tryCta}>Try for free </button>

            <div className={styles.iconSection}>
              <div className={styles.container}>
                <span className={styles.containerBox}>
                  <Mail />
                </span>
                <span className={styles.iconTitle}>Bulk Sms</span>
              </div>
              <div className={styles.container}>
                <span className={styles.containerBox}>
                  <Announce />
                </span>
                <span className={styles.iconTitle}>Email Marketing</span>
              </div>
              <div className={styles.container}>
                <span className={styles.containerBox}>
                  <Search />
                </span>
                <span className={styles.iconTitle}>Analytics & Reports</span>
              </div>
              <div className={styles.container}>
                <span className={styles.containerBox}>
                  <Calender />
                </span>
                <span className={styles.iconTitle}>Reservation Management </span>
              </div>
            </div>
          </aside>
        </section>
        <aside className={styles.rightAside}>
          <section className={styles.imgContainer}>
            <Phone />
          </section>
        </aside>
      </section>
    </section>
  );
};
