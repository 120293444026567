import { ReactComponent as Sicon1 } from '@/assets/icons/s1.svg';
import { ReactComponent as Sicon2 } from '@/assets/icons/s2.svg';
import { ReactComponent as Sicon3 } from '@/assets/icons/s3.svg';
import { ReactComponent as Sicon4 } from '@/assets/icons/s4.svg';
import { ReactComponent as Sicon5 } from '@/assets/icons/s5.svg';
import { ReactComponent as Sicon6 } from '@/assets/icons/s6.svg';
import AboutIcon from '@/assets/images/about-icon.png';
import AboutImg from '@/assets/images/about.png';
import { MainLayout, Footer } from '@/components/Layout/public';
import NavHeader from '@/components/Layout/public/NavHeader';

import { Services } from './components/Services';
import styles from './index.module.scss';

export const About = () => {
  return (
    <MainLayout>
      <main style={{ position: 'relative' }} className={styles.container}>
        <NavHeader />

        <section className={styles.containerBox}>
          <div className={styles.wrapper}>
            <div className={styles.left}>
              <h3>About U</h3>
              <p>
                Zondos is an email and SMS delivery solution. The software allows users to send and
                design their own promotional, newsletters, transactional emails and SMS, and
                features database management, email and SMS automation, landing page management, API
                and integrations, and more. The company was founded in 2019 to provide email
                marketing services. Now Zondos offers a wide range of marketing products and tools
                that empower businesses to bring in more leads and convert them into customers. You
                can build websites, manage your sales and leads in your CRM, and use multiple
                communication channels to interact with your customers:email and SMS, push
                notifications, and chatbots.
              </p>
            </div>
            <div className={styles.right}>
              <img src={AboutImg} alt="about img" className={styles.aboutImg} />
              <div className={styles.aboutIconWrap}>
                <img src={AboutIcon} alt="about icon" />
              </div>
            </div>
          </div>
        </section>
        <section className={styles.serviceContainer}>
          <div className={styles.serviceWrapper}>
            <div className={styles.serviceHeader}>
              <div className={styles.serviceTitle}>Why Choose Zondos?</div>
              <p className={styles.servicePara}>
                Looking for a marketing automation service? Let’s walk through some of Zondos
                features and capabilities that will help businesses connect with prospects and
                customers.
              </p>
            </div>
            <div className={styles.serviceList}>
              <div className={styles.serviceItemList}>
                <Services
                  Icon={<Sicon2 />}
                  title="Statistics Report"
                  description="Monitor the result of your campaigns and improve them by tracking opens and clicks, unsubscribes, delivery errors, and spam complaints."
                />
              </div>
              <div className={styles.serviceItemList}>
                <Services
                  Icon={<Sicon4 />}
                  title="Upload Contacts in .CSV"
                  description="Do you need to upload contacts? You can now upload numbers that are in .CSV format."
                />
              </div>
              <div className={styles.serviceItemList}>
                <Services
                  Icon={<Sicon5 />}
                  title="Free Templates"
                  description="Rather than building an email layout from scratch, you can use pre-designed templates a new email campaign. All templates are grouped by categories"
                />
              </div>
              <div className={styles.serviceItemList}>
                <Services
                  Icon={<Sicon3 />}
                  title="Drag and Drop Editor"
                  description="Create responsive email templates with no coding skills required. Add images, text, buttons, and other elements to your email and modify their size, color, and style to match your brand."
                />
              </div>
              <div className={styles.serviceItemList}>
                <Services
                  Icon={<Sicon6 />}
                  title="Resend to Unopened"
                  description="Increase the open rate of your campaign by resending an email with a new subject line to everyone who has not opened your previous email."
                />
              </div>
              <div className={styles.serviceItemList}>
                <Services
                  description="The free plan is a pocketful of useful features and you can use it if your mailing list contains up to 500 subscribers. When you go over this limit, you can upgrade to any paid plan."
                  Icon={<Sicon1 />}
                  title="Affordable Prices and Free Plan"
                />
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </main>
    </MainLayout>
  );
};
