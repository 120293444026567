import * as React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import { FormWrapper, FormWrapperPassThroughProps } from '../FormWrapper';

import styles from './index.module.scss';

type Option = {
  label: React.ReactNode;
  value: string | number | string[];
};

type SelectFieldProps = FormWrapperPassThroughProps & {
  options: Option[];
  className?: string;
  defaultValue?: string;
  placeholder?: string;
  registration?: Partial<UseFormRegisterReturn>;
} & React.SelectHTMLAttributes<HTMLSelectElement>;

export const SelectField = (props: SelectFieldProps) => {
  const { label, options, error, className, defaultValue, registration, placeholder, ...rest } =
    props;
  const classString = `${styles.selectField} ${className}`;
  return (
    <FormWrapper label={label} error={error}>
      <select
        placeholder={placeholder}
        name="location"
        className={classString}
        defaultValue={defaultValue}
        {...rest}
        {...registration}
      >
        {options.map(({ label, value }) => (
          <option key={label?.toString()} value={value}>
            {label}
          </option>
        ))}
      </select>
    </FormWrapper>
  );
};
