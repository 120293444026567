import { Collapse } from 'antd';
import { FiMinus, FiPlus } from 'react-icons/fi';

import { MainLayout, Footer } from '@/components/Layout/public';
import NavHeader from '@/components/Layout/public/NavHeader';

import style from './index.module.scss';
import './index.scss';

const { Panel } = Collapse;

export const Faq = () => {
  return (
    <MainLayout>
      <main style={{ position: 'relative' }} className={style.container}>
        <NavHeader />

        <div className={style.container_wrapper}>
          <div className={style.content_container}>
            <h3 className={style.faqHeader}>FAQ</h3>
            <Collapse
              bordered={false}
              expandIconPosition="right"
              expandIcon={({ isActive }) =>
                isActive ? (
                  <span>
                    <FiMinus color="#7c2ecb" size={15} />{' '}
                  </span>
                ) : (
                  <span>
                    <FiPlus color="#7c2ecb" size={15} />
                  </span>
                )
              }
              className="site-collapse-custom-collapse site-collapse-custom-collapse-faq"
            >
              <Panel
                header="What is included in a Zondos free trial?"
                key="1"
                className="site-collapse-custom-panel site-collapse-custom-panel-faq"
              >
                <p>
                  You will have full access to all features during your Zondos free trial. No credit
                  card details are required.
                  <br /> We provide you with FREE 500 SMS and 500 emails You can test the service
                  for a maximum 30 days. <br /> You can test sending SMS globally, as long as you
                  have credit left
                </p>
              </Panel>
              <Panel
                header="Zondos offers the following pricing plan options:"
                key="2"
                className="site-collapse-custom-panel site-collapse-custom-panel-faq"
              >
                <div>
                  <p>Zondos offers the following pricing plan options:</p>
                  <ul className={style.eliOrder}>
                    <li>
                      Pay as you go (pricing plans differ based on the available features and
                      mailing list size. Purchased emails are valid for a year)
                    </li>
                    <li>
                      Monthly subscription: Enterprise (pricing plans differ based on the available
                      features and mailing list size)
                    </li>
                  </ul>
                  <p>
                    With the Free plan, you can send SMS/emails to up to 500 subscribers. To send
                    emails to more users, choose one of our paid pricing plans based on the needs,
                    size, and nature of your business.
                  </p>
                  <p>Check our pricing page to find out more about our plans.</p>
                </div>
              </Panel>
              <Panel
                header="I’d like to get dedicated support to set everything up. Can I get a Customer Success Manager?"
                key="3"
                className="site-collapse-custom-panel site-collapse-custom-panel-faq"
              >
                <p>
                  All Standard and Pro plan users with get a dedicated Customer Success Manager who
                  provides migration support, helps set your first automations & campaigns and
                  provides ongoing personalized support to make sure you’re making the most of
                  zondos.
                </p>
              </Panel>
              <Panel
                header="This will be my first time setting up an automation workflow. Can I expect any help?"
                key="4"
                className="site-collapse-custom-panel site-collapse-custom-panel-faq"
              >
                <p>
                  Absolutely. Our 24/7 support team is always there to answer any questions. But
                  frankly - you’ll see that creating your first workflow with Zondos is much easier
                  than you might think - we have pre-built workflow templates and everything can be
                  customized using a no-code, drag & drop Automation Editor.
                </p>
              </Panel>
              <Panel
                header="What is the difference between Enterprise subscription plan andpay as you go?"
                key="5"
                className="site-collapse-custom-panel site-collapse-custom-panel-faq"
              >
                <ul className={style.eliOrder}>
                  <li>
                    Enterprise subscription plans are auto-renewable, meaning that you will be
                    charged for your pricing plan each month from the moment you activate it.
                  </li>
                  <li>
                    Pay-as-you-go plans allow you to send a certain number of campaigns throughout
                    the year. To send more emails, buy a pay-as-you-go plan again.
                  </li>
                </ul>
              </Panel>
              <Panel
                header="I have more questions. How can I reach you?"
                key="6"
                className="site-collapse-custom-panel site-collapse-custom-panel-faq"
              >
                <p>
                  Simply send us an email at{' '}
                  <a href="mailto:suport@zondos.com">suport@zondos.com</a> We’re checking it 24/7
                  and we’ll be happy to answer any of your questions.
                </p>
              </Panel>
            </Collapse>
          </div>
        </div>

        <Footer />
      </main>
    </MainLayout>
  );
};
