import { BsArrowUpRight } from 'react-icons/bs';

import styles from './index.module.scss';

type FeaturesProps = {
  orderLeft?: number;
  orderRight?: number;
  src: string;
  description: string;
  title: string;
  btnText?: string;
  background?: string;
};

export const Features = ({
  orderLeft,
  src,
  orderRight,
  description,
  title,
  btnText,
  background = '#f5f8fa',
}: FeaturesProps) => {
  return (
    <div className={styles.container} style={{ background }}>
      <div className={styles.wrapper}>
        <div className={styles.left} style={{ order: orderLeft }}>
          <h3 className={styles.leftHeader}>{title}</h3>
          <p className={styles.description}>{description}</p>

          <button className={styles.customBtn}>
            <span>{btnText}</span>
            <span>
              <BsArrowUpRight />
            </span>
          </button>
        </div>

        <div className={styles.right} style={{ order: orderRight }}>
          <img src={src} alt="logo" className={styles.imgCont} />
        </div>
      </div>
    </div>
  );
};
